import React from "react"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
const SocialMediaIcons = () => {
  return (
    <ul className="social-media-icon-list">
      <li>
        <a
          href="https://twitter.com/MorningPersonG"
          target="_blank"
          rel="noopener noreferrer"
          className="white-link"
        >
          <FontAwesomeIcon icon={["fab", "twitter"]} />
        </a>
      </li>
      <li>
        <a
          href="https://discord.gg/RHKfCgw"
          target="_blank"
          rel="noopener noreferrer"
          className="white-link"
        >
          <FontAwesomeIcon icon={["fab", "discord"]} />
        </a>
      </li>
      <li>
        <a
          href="https://www.youtube.com/channel/UC_-9qtGLj-cqkjC235W4Pxg"
          target="_blank"
          rel="noopener noreferrer"
          className="white-link"
        >
          <FontAwesomeIcon icon={["fab", "youtube"]} />
        </a>
      </li>
    </ul>
  )
}

export default SocialMediaIcons
