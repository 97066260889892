import React from "react"
import "./../styles/main.scss"
import Header from "./header"
import { scale } from "../utils/typography"
import { library } from "@fortawesome/fontawesome-svg-core"
import {
  fab,
  faYoutube,
  faDiscord,
  faTwitter,
} from "@fortawesome/free-brands-svg-icons"
import { faBars, faTimes } from "@fortawesome/free-solid-svg-icons"
import SocialMediaIcons from "./social-media-icons"

library.add(fab, faTwitter, faDiscord, faYoutube, faBars, faTimes)

console.log(library)

const Layout = ({ location, children }) => {
  let isRoot = false
  const rootPath = `${__PATH_PREFIX__}/`
  if (location != null && location.pathname === rootPath) {
    isRoot = true
  }
  return (
    <div
      id="Main-Content"
      style={{
        marginLeft: `auto`,
        marginRight: `auto`,
      }}
    >
      <Header isRoot={isRoot} />
      <main>{children}</main>
      <footer className="text-center secondary-background">
        <div className="container">
          <SocialMediaIcons />
          <p style={{ ...scale(-0.3) }}>
            © {new Date().getFullYear()} Morning Person Games
          </p>
        </div>
      </footer>
    </div>
  )
}

export default Layout
