import React, { useState } from "react"
import { Link } from "gatsby"
import { scale } from "../utils/typography"
import { Logo, TextLogo } from "./svgs/logo"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import SocialMediaIcons from "./social-media-icons"

const Header = ({ isRoot }) => {
  const [hovered, setHovered] = useState(false)
  const [expanded, setExpanded] = useState(false)
  const Hover = () => setHovered(true)
  const NoHover = () => setHovered(false)
  const toggleExpanded = () => setExpanded(!expanded)
  let links
  let textLogo
  if (isRoot) {
    textLogo = (
      <Link
        id="text-logo"
        style={{
          position: "absolute",
          bottom: "-6em",
          padding: "1em",
        }}
        onMouseOver={Hover}
        onMouseOut={NoHover}
        onFocus={Hover}
        onBlur={NoHover}
        to="/"
      >
        <TextLogo />
      </Link>
    )
  }
  if (!expanded) {
    links = (
      <div
        className={
          hovered ? "container gradient hovered" : "container gradient"
        }
      >
        <Link activeClassName="active" className="owgtowg" to="/owgtowg">
          <h2>OWGTOWG</h2>
        </Link>
        <Link activeClassName="active" className="heartless" to="/heartless">
          <h2>Heartless</h2>
        </Link>
        <Link
          activeClassName="active"
          to="/"
          id="logo"
          onMouseOver={Hover}
          onMouseOut={NoHover}
          onFocus={Hover}
          onBlur={NoHover}
        >
          <Logo style={{ width: "90px", marginBottom: "-1px" }} />
        </Link>
        <Link activeClassName="active" className="right" to="/blog">
          <h2>Blog</h2>
        </Link>
        <Link activeClassName="active" className="right" to="/contact">
          <h2>Contact</h2>
        </Link>
        {textLogo}
        <button onClick={toggleExpanded}>
          <FontAwesomeIcon icon="bars" />
        </button>
      </div>
    )
  } else {
    links = (
      <div id="Expanded-Menu">
        <Link activeClassName="active" className="right" to="/">
          <h2>Home</h2>
        </Link>
        <Link activeClassName="active" className="owgtowg" to="/owgtowg">
          <h2>OWGTOWG</h2>
        </Link>
        <Link activeClassName="active" className="heartless" to="/heartless">
          <h2>Heartless</h2>
        </Link>
        <Link activeClassName="active" className="right" to="/blog">
          <h2>Blog</h2>
        </Link>
        <Link activeClassName="active" className="right" to="/contact">
          <h2>Contact</h2>
        </Link>
        <button onClick={toggleExpanded}>
          <FontAwesomeIcon icon="times" />
        </button>
      </div>
    )
  }
  return (
    <header
      id="Primary-Header"
      className={expanded ? "expanded" : ""}
      style={{ ...scale(-0.3), marginBottom: isRoot ? "5.5em" : "0" }}
    >
      {links}
      <SocialMediaIcons />
    </header>
  )
}

export default Header
